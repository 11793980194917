import "./Header.css"
import sign from './sign.png';
import photoID from './photoID.jpg';
import React, { useState, useEffect } from 'react';

const Header = () => {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 970px)").matches
      )
    
    useEffect(() => {
        window
        .matchMedia("(min-width: 970px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <>
        {matches && (
            <>
            <div className="myHeader">
                <img className="logoImage" src={sign} alt="logo"></img>
                <div className="bioContainer">
                    <h1>Philippe Carvin</h1>
                    <p>Attiré par le vivant, cet artiste est fasciné par l'énergie de la nature et des humains.</p>
                    <p>Après des années à explorer les paysages, il se consacre désormais à l'art vivant : cabaret, danse, spectacles.</p>
                    <p>Il capture l'instant d'une danse, l'éclat des couleurs et l'émotion d'une scène.</p>
                    <p>Ses toiles rendent hommage au spectacle, où lumière, formes et émotions s'unissent pour recréer ces moments magiques où l'art et la vie fusionnent.</p>
                </div>
                <img className="photoID" src={photoID} alt="logo"></img>
            </div>
            <hr/>
            </>
        )}
        {!matches && (
            <>
            <div className="myHeader">
                <img className="logoImage" src={sign} alt="logo"></img>
                
                <img className="photoID" src={photoID} alt="logo"></img>

                
            </div>
            <div className="bioContainer">
                <p>MA PEINTURE</p>
                <p>Figuratif, dessins ou peintures rehaussées de couleur et de lumières tous les thèmes me passionne dans une expression de plaisir et de libertés,</p>
                <p>Réaliser un pont mystérieux entre l'âme du tableaux et des personnages incluant le spectateur,</p>
                <p>Élève de Jean Abadie et de René Brassart que je ne remercierai jamais assez.</p>
                <h1>Philippe Carvin</h1>
            </div>
        <hr/>
        </>
        )}
        </>
    );
}

export default Header